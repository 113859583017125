export default function loadSVGSprite(url) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
            const div = document.createElement('div');
            div.style.display = 'none';
            div.innerHTML = xhr.responseText;

            domReady(function () {
                document.body.insertBefore(div, document.body.firstChild);
            });
        } else {
            console.error('Errore nel caricamento dello spritemap SVG:', xhr.statusText);
        }
    };
    xhr.onerror = function() {
        console.error('Errore di rete durante il caricamento dello spritemap SVG.');
    };
    xhr.send();
}

function domReady(callback) {
    if (
        document.readyState === 'complete' ||
        (document.readyState !== 'loading' && !document.documentElement.doScroll)
    ) {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}